import * as styles from "./pageHeader.module.scss";
import cx from "classnames";
import React from "react";

const PageHeader = ({ title, desc, className }) => (
  <div className={cx(styles.pageHeaderWrapper, className)}>
    <h2 className={styles.header}>{title}</h2>
    {desc && <p className={styles.description}>{desc}</p>}
  </div>
);

export default PageHeader;
