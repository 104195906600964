import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/PageHeader/pageHeader";
import TwoColumnsSection from "../components/TwoColumnsSection/twoColumnsSection";
import FeaturesList from "../components/FeaturesList/featuresList";
import home from "./../components/PhoneSection/images/home.png";
import PhoneSection from "../components/PhoneSection/phoneSection";
import notifications from "./../components/PhoneSection/images/notifications.png";
import call from "./../components/PhoneSection/images/call.png";
import Review from "../components/forPatients/Review/review";
import EverythingInOnePlaceSection from "../components/forPatients/EverythingInOnePlaceSection/everythingInOnePlaceSection";
import { useTranslation } from "react-i18next";
import * as styles from "../components/forPatients/styles.module.scss";

const ForPatientsPage = () => {
  const { t } = useTranslation();
  const patientsPageData = [
    {
      title: t("patient.information.title"),
      desc: t("patient.information.copy"),
      features: [
        t("patient.information.bullet.1"),
        t("patient.information.bullet.2"),
        t("patient.information.bullet.3"),
      ],
    },
    {
      title: t("patient.platform.title"),
      desc: t("patient.platform.copy"),
      features: [
        t("patient.platform.bullet.1"),
        t("patient.platform.bullet.2"),
        t("patient.platform.bullet.3"),
      ],
    },
    {
      title: t("patient.communication.title"),
      desc: t("patient.communication.copy"),
      features: [
        t("patient.communication.bullet.1"),
        t("patient.communication.bullet.2"),
        t("patient.communication.bullet.3"),
        t("patient.communication.bullet.4"),
      ],
    },
    {
      title: t("patient.digitalExperience.title"),
      desc: t("patient.digitalExperience.copy"),
      features: [],
    },
    {
      title: t("patient.onePlace.title"),
      desc: t("patient.onePlace.copy"),
      features: [
        t("patient.onePlace.bullet.1"),
        t("patient.onePlace.bullet.2"),
      ],
    },
  ];
  return (
    <Layout menuColor={"black"} footerColor={"aquamarine"}>
      <SEO title={t("menu.forPatients")} />
      <PageHeader
        className={styles.header}
        title={t("patient.header")}
        desc={t("patient.subheader")}
      />
      <TwoColumnsSection>
        <PhoneSection
          screen={home}
          decoration="decorationType1"
          enterAnimation="fadeInLeft"
        />
        <FeaturesList data={patientsPageData[0]} />
      </TwoColumnsSection>
      <TwoColumnsSection reverseOrderOnMobile>
        <FeaturesList data={patientsPageData[1]} />
        <PhoneSection
          screen={notifications}
          decoration="decorationType2"
          enterAnimation="fadeInRight"
        />
      </TwoColumnsSection>
      <TwoColumnsSection>
        <PhoneSection
          screen={call}
          decoration="decorationType3"
          enterAnimation="fadeInLeft"
        />
        <FeaturesList data={patientsPageData[2]} />
      </TwoColumnsSection>
      <TwoColumnsSection reverseOrderOnMobile>
        <FeaturesList data={patientsPageData[3]} />
        <Review />
      </TwoColumnsSection>
      <TwoColumnsSection>
        <EverythingInOnePlaceSection />
        <FeaturesList data={patientsPageData[4]} />
      </TwoColumnsSection>
    </Layout>
  );
};

export default ForPatientsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
