import * as styles from "./phoneSection.module.scss";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const PhoneSection = ({ screen, decoration, enterAnimation }) => (
  <div className={styles.phoneSectionWrapper}>
    <div className={styles.phoneWrapper}>
      <div className={styles[decoration]} />
      <ScrollAnimation
        animateIn={enterAnimation}
        animateOnce
        style={{ zIndex: 50, position: "relative" }}
      >
        <div className={styles.phoneImageWrapper}>
          <img className={styles.phoneImage} src={screen} alt="" />
        </div>
      </ScrollAnimation>
    </div>
  </div>
);

export default PhoneSection;
