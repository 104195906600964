import * as styles from "./twoColumnsSection.module.scss";
import cx from "classnames";
import React from "react";

const TwoColumnsSection = ({ children, reverseOrderOnMobile, className }) => (
  <div
    className={cx(
      styles.twoColumnsWrapper,
      className && className,
      reverseOrderOnMobile && styles.reverseOrder
    )}
  >
    {children}
  </div>
);

export default TwoColumnsSection;
