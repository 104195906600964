// extracted by mini-css-extract-plugin
export var phoneSectionWrapper = "everythingInOnePlaceSection-module--phoneSectionWrapper--2xZZk";
export var phoneWrapper = "everythingInOnePlaceSection-module--phoneWrapper--16c-o";
export var phoneImage = "everythingInOnePlaceSection-module--phoneImage--vzoyP";
export var decoration = "everythingInOnePlaceSection-module--decoration--3AWJw";
export var cameraIcon = "everythingInOnePlaceSection-module--cameraIcon--1qYFW";
export var messageIcon = "everythingInOnePlaceSection-module--messageIcon--WDHYM";
export var infoIcon = "everythingInOnePlaceSection-module--infoIcon--1zz-W";
export var creditIcon = "everythingInOnePlaceSection-module--creditIcon--31VGZ";
export var playIcon = "everythingInOnePlaceSection-module--playIcon--199G3";
export var decorationPurple = "everythingInOnePlaceSection-module--decorationPurple--3xA-W";
export var decorationGreen = "everythingInOnePlaceSection-module--decorationGreen--1A1YU";