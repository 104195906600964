import * as styles from "./review.module.scss";
import React from "react";
import appStore from "./appstore.jpg";
import ScrollAnimation from "react-animate-on-scroll";

const Review = () => (
  <div className={styles.reviewWrapper}>
    <div className={styles.decorationWrapper}>
      <div className={styles.decoration} />
      <ScrollAnimation
        animateIn="fadeInRight"
        animateOnce
        style={{ zIndex: 50, position: "relative" }}
      >
        <img className={styles.phone} src={appStore} alt="" />
      </ScrollAnimation>
    </div>
  </div>
);

export default Review;
