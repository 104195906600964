import * as styles from "./everythingInOnePlaceSection.module.scss";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import cameraIcon from "./images/camera.png";
import messageIcon from "./images/message.png";
import infoIcon from "./images/i.png";
import creditIcon from "./images/credit.png";
import playIcon from "./images/play.png";
import phone from "./images/lock.png";

const EverythingInOnePlaceSection = () => {
  return (
    <div className={styles.phoneSectionWrapper} id="everything">
      <div className={styles.phoneWrapper}>
        <div className={styles.decoration}>
          <ScrollAnimation animateIn="bounceIn" delay={100} animateOnce>
            <img src={cameraIcon} alt="" className={styles.cameraIcon} />
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceIn" delay={200} animateOnce>
            <img src={messageIcon} alt="" className={styles.messageIcon} />
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceIn" delay={300} animateOnce>
            <img src={infoIcon} alt="" className={styles.infoIcon} />
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceIn" delay={400} animateOnce>
            <img src={creditIcon} alt="" className={styles.creditIcon} />
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceIn" delay={500} animateOnce>
            <img src={playIcon} alt="" className={styles.playIcon} />
          </ScrollAnimation>
        </div>
        <img className={styles.phoneImage} src={phone} alt="" />
        <div className={styles.decorationPurple} />
        <div className={styles.decorationGreen} />
      </div>
    </div>
  );
};

export default EverythingInOnePlaceSection;
