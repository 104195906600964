import * as styles from "./featuresList.module.scss";
import cx from "classnames";
import React, { useState } from "react";

const FeaturesList = ({ className, data }) => {
  const [showFeatures, setShowFeatures] = useState(false);
  if (data) {
    const areFeaturesPresent = data.features.length !== 0;
    const renderFeatures = () => {
      if (!areFeaturesPresent) {
        return null;
      }
      return data.features.map((feature, index) => (
        <li key={index} className={styles.feature}>
          {feature}
        </li>
      ));
    };
    return (
      <div className={cx(styles.featuresListWrapper, className)}>
        <h3 className={styles.featureTitle}>{data.title}</h3>
        <p className={styles.description}>{data.desc}</p>
        <ul
          className={cx(
            styles.mobileFeatureList,
            showFeatures && styles.showFeatures
          )}
        >
          {renderFeatures()}
        </ul>
        {areFeaturesPresent && (
          <span
            className={styles.readMore}
            onClick={() => setShowFeatures((v) => !v)}
            onKeyPress={() => setShowFeatures((v) => !v)}
            role="button"
            tabIndex={0}
            aria-label={showFeatures ? "Read Less" : "Read more"}
          >
            <p>
              {showFeatures ? "Read Less" : "Read more"}
            </p>
          </span>
        )}
        <ul className={styles.featuresList}>{renderFeatures()}</ul>
      </div>
    );
  }
  return null;
};

export default FeaturesList;
